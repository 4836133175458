.Heading {
  background-color: #f6f6f6;
  color: black;
  border: 1.5px solid black;
  transition: 0.3s;
  border-radius: 20px;
}
.Heading2 {
  color: black;
  border: 1.5px solid black;
  transition: 0.3s;
  height: fit-content;
  border-radius: 20px;
}
.Heading3 {
  background-color: #e76929;
  color: black;
  border: 1.5px solid black;
  transition: 0.3s;
  height: fit-content;
  border-radius: 20px;
}
.Heading:hover {
  background-color: #e76929;
  opacity: 1;
}

.SingleCard {
  border: 2px solid black;
  width: 90vw;
  color: white;
  text-align: center;
  margin: 5px 0;
  border-radius: 20px;
  justify-content: space-between;
}

.alert {
  padding: 10px;
  background-color: greenyellow;
  color: black;
  border-radius: 20px;
  margin: 0 0 5px 0;
}

.SubCard {
  border: 1px solid black;
  background-color: #f6f6f6;
  width: 18vw;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  border-radius: 20px;
  margin: 5px;
}
.RegBtn {
  border-radius: 20px;
  border: 3px solid black;
  color: #e76929;
  margin: 5px;
  width: 80%;
}

.Heading2:hover {
  background-color: #e76929;
  opacity: 1;
}

@media only screen and (max-width: 700px) {
  .SubCard {
    font-size: 10px;
  }
}
