.full {
  margin-top: 50px;
  justify-content: space-around;
}

.left {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload {
  font-size: x-large;
  width: max-content;
  font-weight: bold;
}
.uploadSection {
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  margin: 50px;
}
.sectionAbove {
  width: 500px;
}

#hideMeAfter5Seconds {
  animation: hideAnimation 0s ease-in 3s;
  animation-fill-mode: forwards;
}

@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

@media only screen and (max-width: 950px) {
  .upload {
    font-size: large;
  }
  .full {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .uploadSection {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 700px) {
  .upload {
    font-size: small;
  }
  .full {
    width: 100vw;
    display: flex;
    margin: 0;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: -1;
    justify-content: center;
    margin-top: 12vh;
  }

  .uploadSection {
    grid-template-columns: 1fr 1fr;
  }
}
