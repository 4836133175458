.outer {
  height: 120px;
  width: 200px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid black;
  margin: 25px;
}

.label {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 60px;
  padding: 20px 0;
  border-radius: 30px;
  transition: 0.3s;
}

.uploadImg {
  margin-bottom: 10px;
  width: 50px;
}

.selectAFile {
  font-size: 12px;
  margin-bottom: 10px;
}

.label:hover {
  background-color: #e76929;
  opacity: 1;
}

.labelDisable {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  padding: 20px 0;
  border-radius: 30px;
  transition: 0.3s;
}

.fileName {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}
@media only screen and (max-width: 500px) {
  .outer {
    height: 170px;
    width: 150px;
  }

  .label {
    width: 140px;
  }
  .fileName {
    font-size: 10px;
  }
  .labelDisable {
    width: 140px;
  }
}
