.PhoneScreen {
  display: none;
  position: fixed;
  width: 100vw;
  background-color: white;
}

.NavHandler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid black;
  padding: 0 10px;
  height: 10vh;
}

.NavLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  justify-content: space-around;
  font-weight: bold;
}

.Link {
  height: fit-content;
  text-align: center;
  width: 100%;
  font-size: 20px;
  padding: 25px;
  transition: background-color ease-in 0.3s;
}
.Link2 {
  background-color: #e76929;
  height: fit-content;
  font-weight: bold;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 20px;
  padding: 25px;
  transition: background-color ease-in 0.3s;
}

.btn {
  background-color: #e76929;
}

.Link:hover {
  color: #e76929;
  background-color: black;
}
@media only screen and (max-width: 700px) {
  .PhoneScreen {
    display: block;
  }
}
