form {
  background: #f6f6f6;
}

.inputt {
  background-color: #f6f6f6;
  margin-left: 15px;
}
input::placeholder {
  color: black;
  font-weight: 500;
  opacity: 10;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media only screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: 1fr;
    margin: 50px 0;
  }
}

@media only screen and (max-width: 600px) {
  .fullSearchOuter {
    width: 200px;
  }
}
