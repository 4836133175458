.btn {
  background: #f6f6f6;
  color: black;
  border: 2px solid black;
  width: 90%;
  padding: 5px;
  text-align: center;
  margin: 2px 0 8px 0;
  border-radius: 20px;
}

.btn:hover {
  background-color: #e76929;
}
