.footer {
  background-color: black;
  color: white;
  text-align: center;
  position: fixed;
  padding: 3px 0;
  bottom: 0;
  width: 100vw;
  left: 0;
  font-size: x-small;
}
